import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";
import Text from "../ui/Text";
import Image from "../ui/Image";
import duck from "../../assets/images/duckLong.JPG";

export default function Experience() {
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Text {...styles.TitleText}>Experience</Text>
            <Image src={duck} {...styles.DuckImage}/>
            <Footer/>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "60px"
    },
    DuckImage: {
        width: "100%"
    }
}