import React, {useState} from "react";
import Flex from "./Flex";
import Text from "./Text";
import Image from "./Image";
import {colors, fonts} from "./theme/theme";
import {useNavigate} from "react-router-dom";
import { isMobile } from "react-device-detect";

import lsfLogo from "../../assets/images/LeSavoirFareLOGO.png";
import HeaderMobile from "./HeaderMobile";
import headerLinks from "./headerLinks";

export default function Header() {

    if (isMobile) {
        return (
            <HeaderMobile/>
        );
    }

    return (
        <Flex {...styles.Container}>
            <Flex {...styles.LeftContainer}>
                <Image src={lsfLogo} {...styles.lsfLogo}/>
            </Flex>
            <Flex {...styles.RightContainer}>
                <Flex {...styles.WebMenuContainer}>
                    {headerLinks.map((menuItem) => {
                        return (
                            <WebMenuButton {...menuItem}/>
                        );
                    })}
                </Flex>
            </Flex>
        </Flex>
    );
}

const WebMenuButton = function ({label, path}) {

    const navigate = useNavigate();
    const [hovering, setHovering] = useState(false);
    const selected = window.location.pathname == path;
    const highlightButton = selected || hovering;

    return (
        <Flex 
            {...styles.WebMenuButton}
            cursor={"pointer"}
            onMouseEnter={() => {setHovering(true)}}
            onMouseLeave={() => {setHovering(false)}}
            onClick={() => {
                navigate(path);
            }}
        >
            {
                highlightButton ? 
                    <Flex {...styles.WebMenuButtonBarSelected}/> : 
                    <Flex {...styles.WebMenuButtonBarUnselected}/>
            }
            <Text
                color={highlightButton ? colors.blueHighlighter : colors.olive}
                {...styles.WebMenuButtonText}  
            >
                {label}
            </Text>
        </Flex>
    );
}


const styles = {
    Container: {
        width: "100vw",
        height: "84px",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "48px",
        marginBottom: "48px",
        alignItems: "center",
        zIndex: 1,
    },
    LeftContainer: {
        width: "50vw",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    RightContainer: {
        width: "50vw",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        minWidth: "600px",
    },
    WebMenuContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "84px",
    },
    WebMenuButton: {
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        paddingLeft: "8px",
        paddingRight: "8px",
        marginLeft: "5px",
        marginRight: "5px",
        position: "relative",
        fontFamily: fonts.robotoLight,
        cursor: "pointer"
    },
    WebMenuButtonText: {
        fontSize: "16px",
    },
    WebMenuButtonBarSelected: {
        position: "absolute",
        top: "0px",
        right: "0px",
        left: "0px",
        width: "100%",
        height: "4px",
        backgroundColor: colors.olive,
    },
    WebMenuButtonBarUnselected: {
        position: "absolute",
        top: "0px",
        right: "0px",
        left: "0px",
        width: "100%",
        height: "1px",
        backgroundColor: "rgb(217,219,215)",
    },
    lsfLogo: {
        width: "509px",
        // height: "113px"
    },
};
