import React from "react";
import styled, { css } from "styled-components";
import {
    color,
    layout,
    position,
    space,
    typography,
} from "styled-system";
import {
    hoverColor,
} from "./theme/customProperties";

export default React.memo(styled.div`
    ${color};
    ${layout};
    ${position};
    ${space};
    ${typography};
    white-space: pre-line;

    &:hover {
    ${hoverColor};
    }
`);


