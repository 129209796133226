export default [
    {
        label: "Home",
        path: "/"
    },
    {
        label: "About Us",
        path: "/about-us"
    },
    {
        label: "Experience",
        path: "/experience"
    },
    {
        label: "Gallery",
        path: "/gallery"
    },
    {
        label: "Contributors",
        path: "/contributors"
    },
    {
        label: "Giving",
        path: "/giving"
    },
    {
        label: "Contact Us",
        path: "/contact-us"
    }

];