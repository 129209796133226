import React from "react";
import Flex from "../ui/Flex";
import Image from "../ui/Image";
import Text from "../ui/Text";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";

import crab from "../../assets/images/CrabCropped.jpg"
import mushrooms from "../../assets/images/oyster.webp"
import romanesco from "../../assets/images/romanesco1.JPG";
import lsfLogo from "../../assets/images/LeSavoirFareLOGOLong.png";
import drape from "../../assets/images/drape.jpg";
import {useNavigate} from "react-router-dom";

export default function Home() {
    const navigate = useNavigate();
    return (
        <Flex {...styles.Container}>
            <Header/>
                <Flex {...styles.HeroContainer}>
                    <Image src={crab} {...styles.CrabImage}/>
                    <Flex {...styles.RightSideContainer}>
                        <Image src={mushrooms} {...styles.MushroomImage}/>
                        <Flex {...styles.HeroTextContainer}>
                            <Text {...styles.HeroTitleText}>
                                Creating taste, not waste.
                            </Text>
                            <Text {...styles.HeroBodyText}>
                                Our concept is to orchestrate an innovative farm-to-table, private, eco-conscious fine dining experience in the comfort of your home.
                            </Text>
                            <Text
                                onClick={() => {
                                    navigate("/about-us");
                                }} 
                                {...styles.HeroSubtext}
                            >
                                Learn more
                            </Text>
                        </Flex>
                    </Flex>

                </Flex>
                <Flex {...styles.BottomContainer}>
                    <Image src={romanesco} {...styles.RomanescoImage}/>
                    <Flex {...styles.DrapeContainer}>
                        <Image src={drape} {...styles.DrapeImage}/>
                        <Flex {...styles.DrapeTextContainer}>
                            <Flex {...styles.DrapeTextSpaceContainer}>
                                <Image src={lsfLogo} {...styles.LsfDrapeLogoImage}/>
                                {
                                    [
                                        "Craftsmanship",
                                        "Innovation",
                                        "Luxury",
                                        "Creativity",
                                        "Modernism",
                                        "Attention to Detail"
                                    ].map((text) => {
                                        return (
                                            <Text {...styles.DrapeText}>
                                                {text}
                                            </Text>
                                        );
                                    })
                                }
                            </Flex>

                        </Flex>
                    </Flex>

                </Flex>
            <Footer/>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    HeroContainer: {
        width: "100vw",
        zIndex: 0,
        height: "950px",
        flexDirection: ["column-reverse", "row"]
    },
    CrabImage: {
        marginTop: ["0px", "-210px"],
        width: ["100vw", "50vw"],
        height: [null, "1160px"],
    },
    RightSideContainer: {
        width: ["100vw", "50vw"],
        height: [null, "1160px"],
        marginTop: ["0px", "-210px"],
    },
    MushroomImage: {
        width: ["100vw", "50vw"],
        height: [null, "1160px"],
    },
    HeroTextContainer: {
        width: ["350px", "50vw"],
        position: "absolute",
        right: ["50%", 0],
        marginRight: ["-175px", 0],
        top: ["170px", "400px"],
        zIndex: 1,
        flexDirection: "column",
        alignItems: "flex-start",
        jusifyContent: "center",
        paddingLeft: "50px"
    },
    HeroTitleText: {
        color: colors.blueTeal,
        fontSize: "35px",
        fontFamily: fonts.cormorandMedium,
    },
    HeroBodyText: {
        color: colors.blueTeal,
        fontSize: "20px",
        fontFamily: fonts.cormorandMedium,
        maxWidth: "380px",
        paddingTop: "20px",
        letterSpacing: "0.05em",
        lineHeight: "28px"
    },
    HeroSubtext: {
        color: colors.blueTeal,
        fontSize: "20px",
        fontFamily: fonts.cormorandLightItalic,
        paddingTop: "20px",
        cursor: "pointer",
    },
    BottomContainer: {
        width: "100vw",
        overflow: "clip",
        height: [null, "1000px"],
        position: "relative",
        zIndex: 0,
        flexDirection: ["column-reverse", "row"]
    },
    RomanescoImage: {
        width: ["100vw","50vw"],
        height: [null, "1000px"],
    },
    DrapeContainer: {
        width: ["100vw","50vw"],
        position: "relative",
        backgroundColor: "yellow",
        alignItems: "center",
        justifyContent: "center",
    },
    DrapeImage: {
        position: "absolute",
        right: 0,
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
    },
    DrapeTextContainer: {
        alignItems: "center",
        justifyContent: "center",
        width: ["90vw","50vw"],
        zIndex: 1,
    },
    DrapeTextSpaceContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: ["450px", "600px"],
        marginBottom: ["80px", null]
    },
    LsfDrapeLogoImage: {
        width: ["300px", "557px"],
        paddingBottom: "20px",
        marginTop: ["80px", null]
    },
    DrapeText: {
        fontSize: ["30px", "40px"],
        fontFamily: fonts.cormorandMedium,
        color: colors.beigeBackground,
    }
}