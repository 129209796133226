import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import Text from "../ui/Text";
import { colors, fonts } from "../ui/theme/theme";
import Image from "../ui/Image";

import chickenWing from "../../assets/images/gallery/chickenwing.JPG";
import consomme from "../../assets/images/gallery/consomme.JPG";
import duck from "../../assets/images/gallery/duck.JPG";
import eggsalad from "../../assets/images/gallery/eggsalad.JPG";
import filetmignon from "../../assets/images/gallery/filetmignon.JPG";
import fishskin from "../../assets/images/gallery/fishskin.JPG";
import lamb from "../../assets/images/gallery/lamb.JPG";
import lamb2 from "../../assets/images/gallery/lamb2.JPG";
import lobster2 from "../../assets/images/gallery/lobster2.JPG";
import NYsteak from "../../assets/images/gallery/NYsteak.JPG";
import pinkoysters from "../../assets/images/gallery/pinkoysters.JPG";
import radishring from "../../assets/images/gallery/radishring.JPG";
import Tortellini from "../../assets/images/gallery/Tortellini.jpg";



export default function Gallery() {
    return (
        <Flex {...styles.Container}>
            <Header/>
                <Text {...styles.TitleText}>Gallery</Text>
                <Flex {...styles.GalleryListContainer}>
                    {
                        galleryPicData.map((props) => {
                            return <GalleryPic {...props}/>
                        })
                    }
                </Flex>
            <Footer/>
        </Flex>
    );
}

const GalleryPic = ({caption, source}) => {
    return (
        <Flex {...styles.GalleryPicContainer}>
            <Image src={source} {...styles.GalleryPicImage}/>
            <Text {...styles.GalleryPicImageCaption}>{caption}</Text>
        </Flex>
    );
}

const galleryPicData = [
    {
        caption: "Salade jardinière de Printemps",
        source: radishring,
    },
    {
        caption: "Rose crusted duck breast, bruleed cara cara, Perigord truffle, caramel duck jus",
        source: duck,
    },
    {
        caption: "Grass fed beef, charred ginger, lemongrass consommé",
        source: consomme,
    },
    {
        caption: "Sea bass skin chip, hamachi belly, avocado, ikura",
        source: fishskin,
    },
    {
        caption: "Prime filet mignon, mushroom, winter greens, beef jus",
        source: filetmignon,
    },
    {
        caption: "Hog Island sweetwater oyster, pickle pearl onion, leche de tigre",
        source: pinkoysters,
    },
    {
        caption: "Sous vide lobster tail, meyer lemon coulis, truffle butter",
        source: lobster2,
    },
    {
        caption: "Grilled dry aged lamb T-Bone, sweet and spicy gochujang, end of winter and early spring assortments",
        source: lamb,
    },
    {
        caption: "Wagyu Tortellini Genoese, parmesan broth, black pepper, basil oil",
        source: Tortellini,
    },
    {
        caption: "Grilled lamb loin, crispy leeks, glazed baby leeks, roasted apple and beet",
        source: lamb2,
    },
    {
        caption: "Salade Lyonnaise, 62* egg, brioche, Okinawa sweet potato",
        source: eggsalad,
    },
    {
        caption: "Crispy confit boneless chicken wing, citrus glaze",
        source:  chickenWing,
    },
    {
        caption: "Seared New York Strip steak, rainbow chard, rainbow chimichurri, demi-glace",
        source: NYsteak,
    },
];



const styles = {
    Container: {
        width: "100vw",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "30px"
    },
    GalleryListContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: ["100vw", "1300px"],
        overflow: "hidden"
    },
    GalleryPicImage: {
        maxWidth: ["95vw", "70vw"],
        maxHeight: "85vh"
    },
    GalleryPicImageCaption: {
        fontFamily: fonts.cormorandMedium,
        fontSize: ["25px", "30px"],
        color: colors.olive,
        paddingBottom: "80px",
        paddingTop: "20px" 
    },
    GalleryPicContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
}