export const colors = {
    black: "#000000",
    white: "#FFFFFF",
    olive: "rgb(77,87,64)",
    blueTeal: "rgb(22,58,61)",
    blueHighlighter: "rgb(86,124,132)",
    beigeBackground: "rgb(241,237,231)"
};

export const radii = {
    tiny: "4px",
    small: "8px",
    medium: "10px",
    large: "25px",
};

export const shadows = {
    primary: "5px 5px 20px 10px rgba(0,0,0,0.1)",
};

export const fontWeights = {
    light: "300",
    regular: "400",
    bold: "500",
    black: "600",
};

export const fonts = {
    cormorandSemiblold: '"Cormorand Garamont Semi Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    cormorandMedium: '"Cormorand Garamont Medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    cormorand: '"Cormorand Garamont Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    cormorandLight: '"Cormorand Garamont Light", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    cormorandLightItalic: '"Cormorand Garamont Light Italic", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    robotoLight: '"Roboto Light", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    primary: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    secondary: '"Space Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    mono: '"Roboto Mono", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
};