import React from "react";
import Flex from "../ui/Flex";
import Image from "./Image";
import { colors } from "./theme/theme";
import lsfLogo from "../../assets/images/LeSavoirFareLOGO.png";


export default function Footer() {
    return (
        <Flex {...styles.Container}>
            <Image src={lsfLogo} {...styles.lsfLogo}/>
        </Flex>
    );
}


const styles = {
    Container: {
        width: "100vw",
        height: "100px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.beigeBackground,
        marginTop: "40px"
    },
    lsfLogo: {
        width: "200px",
    },
}
