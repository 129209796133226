import React from "react";
import Flex from "../ui/Flex";
import Text from "../ui/Text";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";
import Image from "../ui/Image";
import styled from "styled-components";

import leaf from "../../assets/images/leafSquare.jpg";
import lettuce from "../../assets/images/minerslettuce1.JPG";
import zeroFoodPrint from "../../assets/images/zero_foodprint.webp";
import lettuceBackground from "../../assets/images/lettuce_background.png"
import yen from "../../assets/images/yen_long.JPG";
import linkedin from "../../assets/images/linkedin.webp";

export default function AboutUs() {
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Text {...styles.TitleText}>About Us</Text>
            <Flex {...styles.AboutUsContainer}>
                <Flex {...styles.LeftAboutUsContainer}>
                    <Image src={leaf} {...styles.LeafImage}/>
                    <Flex {...styles.GoalsTextContainer}>
                        <Text {...styles.GoalsTitleText}>
                            Our goals towards being a sustainable business:
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            Le Savoir Fare conscientiously involves our clients in the agricultural climate solution movement. We do so by being an active member of the non-profit organization Zero FoodPrint, which manages the Restore California project. We participate by applying a 1% surcharge to our client’s meal, which helps fund renewable farming practices, with the goal of growing food with a focus on replenishing the soil.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            Supporting soil health can help absorb carbon pollution from the atmosphere, improve water quality, and protect against drought, all while cultivating nutrient-rich foods.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            This method is proven to be one of the most impactful initiatives yet towards solving global warming.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            We are dedicated to environmentally-conscious methods of production:
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            <ul>
                                <li>We reduce our carbon imprint by sourcing seasonal produce from local organic farms that are actively taking part in the Sustainable and Regenerative agricultural movement. </li>
                                <li>We are highly-conscious of food waste. We implement various techniques that allow us to utilize parts of ingredients that are otherwise thrown away. </li>
                                <li>We recycle and compost.</li>
                                <li>We purchase energy-efficient, used, or refurbished equipment, and unplug when not actively in use.</li>
                                <li>We’ve eliminated single-use plastic.</li>
                            </ul>
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            We do monthly drop-offs to Smart Alternative Fuels, for the recycling of our cooking oils. 
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            We do not use toxic cleaners. 
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            We provide vegan and vegetarian options for our guests, to encourage consuming foods with fewer associated carbon emissions.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            Our entire company is digitized to eliminate paper usage.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            Eco-friendly packaging.
                        </Text>
                    </Flex>
                    <Flex {...styles.zeroFoodprintContainer}>
                        <Image src={zeroFoodPrint} {...styles.zeroFoodprintImage}/>
                    </Flex>
                    
                </Flex>
                <Flex {...styles.RightAboutUsContainer}>
                    <Flex {...styles.VisionTextContainer}>
                        <Text {...styles.VisionTitleText}>Le Savoir Fare</Text>
                        <Text {...styles.VisionParagraph}>
                            <VisionTextCapsSpan>"Savoir Faire"</VisionTextCapsSpan>{" "}is a French expression originating in the 19th century, directly translating as “know how.” This expression has been used across a variety of industries to express the creativity, innovation, modernism, attention to detail, and luxury that make up the foundations of high-quality craftsmanship. Our spin on this expression is exchanging the French “faire” for the English “fare,” meaning “a range of foods.” This represents our ability to recreate a wide range of cuisines, with expertise. 
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            <VisionTextCapsSpan>Our concept</VisionTextCapsSpan>{" "}is to orchestrate an innovative farm-to-table, private, eco-conscious fine dining experience in the comfort of your home. We aim to restore the climate, one meal at a time. 
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            <VisionTextCapsSpan>Our mission</VisionTextCapsSpan>{" "}is to responsibly source high-quality produce from local organic farmers who are actively taking part in the Sustainable and Regenerative agricultural movement, so we can craft climate-friendly dishes. 
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            <VisionTextCapsSpan>Our vision</VisionTextCapsSpan>{" "} is to become a regenerative company that is part of the solution to the global climate crisis.  
                        </Text>
                    </Flex>
                    <Flex {...styles.LettuceContainer}>
                        <Image src={lettuceBackground} {...styles.LettuceBackgroundImage}/>
                        <Image src={lettuce} {...styles.LettuceImage}/>

                    </Flex>
                </Flex>
            </Flex>
            <Flex {...styles.YenImageContainer}>
                <Image src={yen} {...styles.YenImage}/>
                <Text {...styles.YenniferText}>Yennifer Prada</Text>
            </Flex>
            <Flex {...styles.BioContainer}>
                <Flex {...styles.BioTextContainer}>
                    <Text {...styles.BioText}>
                        Chef Yennifer Prada was born and raised in Venezuela, of Ecuadorian and Colombian descent. She is fortunate to have incredible cooks in her family, who continuously fused Venezuelan cuisine with Ecuadorian and Colombian flare. At the age of 17, she moved to the U.S to pursue a Bachelor of Science in Psychology; after graduating, she began her Associate of Science in Culinary Arts in Los Angeles. 
                    </Text>
                    <Text {...styles.BioText}>
                        In Los Angeles, while volunteering at the
                        {" "}<BioLink target="_blank" href="https://www.lexusallstarchefclassic.com/">All-Star Chef’s Classic</BioLink>
                        , {" "}<BioLink target="_blank" href="https://www.instagram.com/chefludo/">Chef Ludo Lefebvre</BioLink> offered her a position at 
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/petittroisla/">Petit Trois</BioLink>. She was later transferred to his Michelin-starred restaurant, 
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/troismecla/">Trois Mec</BioLink>. After this incredible experience, she was approached with the opportunity to be the opening Sous Chef for 
                        {" "}<BioLink target="_blank" href="https://blacksheeprestaurants.com/">Artemis & Apollo</BioLink> in Hong Kong, working alongside Le Savoir Fare’s co-founder Chef Andrée Lefuel.
                    </Text>
                    <Text {...styles.BioText}>
                        After returning from Hong Kong, she moved to San Francisco to work for 
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/dominiquecrenn/">Chef Dominique Crenn</BioLink>, in her Michelin-starred restaurant, 
                        {" "}<BioLink target="_blank" href="https://www.barcrenn.com/">Bar Crenn</BioLink>. As the COVID-19 Pandemic led to the temporary closure of Bar Crenn and increased food insecurity within the community, she was presented with the opportunity to be the culinary manager of the 
                        {" "}<BioLink target="_blank" href="https://www.rethinkfood.org/">Rethink Food Project</BioLink> running from
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/petitcrenn/">Petit Crenn</BioLink>. Chef Yennifer is deeply committed to social and ecological renewal; she aspires to follow a regenerative lifestyle and hopes Le Savoir Fare will create consciousness and encourage others to do the same. 
                    </Text>
                    <a href="https://www.linkedin.com/in/yennifer-prada-3500a69a" target="_blank">
                        <Image 
                            src={linkedin}
                            {...styles.LinkedinImage}
                        />
                    </a>

                </Flex>
            </Flex>
            <Footer/>
        </Flex>
    );
}
const VisionTextCapsSpan = React.memo(styled.span`
    font-size: 23px;
    font-family: ${fonts.cormorandMedium}
`);

const BioLink = React.memo(styled.a`
    text-decoration: underline 1px;
    cursor: pointer;
    color: rgb(254,250,234);
    &:visited {
        color: inherit;
    }
`);

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: ["30px", "90px"]
    },
    AboutUsContainer: {
        flexDirection: ["column-reverse", "row"],
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100vw",
        paddingLeft: ["0px", "40px"],
        paddingRight: ["0px", "40px"],
    },
    LeftAboutUsContainer: {
        backgroundColor: "rgb(62,82,84)",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        position: "relative",
        height: [null, "1950px"]
    },
    RightAboutUsContainer: {
        backgroundColor: "rgb(206,210,201)",
        flexDirection: "column",
        justifyContent: ["center", "flex-start"],
        alignItems: "center",
        width: "100%",
        zIndex: 1,
        height: [null, "1950px"]
    },
    LeafImage: {
        height: ["300px", "650px"],
        borderRadius: "50%",
        position: "absolute",
        top: "50px",
        right: ["50%", "-150px"],
        marginRight: ["-150px", null],
        zIndex: "0",
    },
    LettuceContainer: {
        maxWidth: "100%",
        padding: ["0px", "40px"],
        position: "relative"
    },
    LettuceImage: {
        width: ["90vw", "610px"],
        height: ["450px", "auto"],
        position: "absolute",
        left: ["5vw", "85px"],
        right: ["5vw", "85px"],
        top: ["25px", "115px"],
        bottom: ["25px", "115px"]
    },
    LettuceBackgroundImage: {
        width: ["100vw", "700px"],
        height: ["500px", "auto"]    
    },
    GoalsTextContainer: {
        paddingTop: ["400px","790px"], // 650 picture size + 50 padding at top + 90 padding below pic
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: ["85vw", "500px"],
    },
    GoalsTitleText: {
        fontFamily: fonts.cormorandMedium,
        color: "rgb(212, 214,209)",
        fontSize: "33px",
        width: "400px",
        paddingBottom: "20px"
    },
    GoalsBodyText: {
        fontFamily: fonts.cormorand,
        color: "rgb(212, 214,209)",
        fontSize: "18px",
        paddingBottom: "15px"
    },
    zeroFoodprintImage: {
        width: "92px",
    },
    zeroFoodprintContainer: {
        flexDirection: "row",
        justifyContent: ["center", "flex-end"],
        alignItems: "center",
        width: "575px",
        paddingBottom: ["60px", "164px"],
        marginTop: ["40px", "-50px"]
    },
    VisionTextContainer: {
        flexDirection: "column",
        maxWidth: ["85vw", "450px"],
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        paddingTop: ["40px", "115px"]
    },
    VisionTitleText: {
        color: colors.olive,
        fontFamily: fonts.cormorandMedium,
        fontSize: "33px",
        paddingBottom: "65px",
        display: ["none", "block"]
    },
    VisionParagraph: {
        color: colors.olive,
        fontFamily: fonts.cormorand,
        fontSize: "18px",
        paddingBottom: "40px",
        lineHeight: "25px"
    },
    YenImageContainer: {
        width: "100%",
        paddingLeft: ["0px","40px"],
        paddingRight: ["0px","40px"],
        position: "relative"
    },
    YenImage: {
        width: "100%"
    },
    YenniferText: {
        position: "absolute",
        fontFamily: fonts.cormorandMedium,
        fontSize: ["45px", "68px"],
        color: [colors.beigeBackground, "#DBC5A4"],
        right: ["3%", "18%"],
        maxWidth: ["200px", null],
        textAlign: ["right", "left"],
        top: ["4%", "30%"]
    },
    BioContainer: {
        paddingLeft: ["0px","40px"],
        paddingRight: ["0px","40px"],
        width: "100%",

    },
    BioTextContainer: {
        width: "100%",
        backgroundColor: "rgb(77,87,65)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "40px",
        paddingLeft: "30px",
        paddingRight: "30px",
    },
    BioText: {
        color: "rgb(254,250,234)",
        fontSize: "18px",
        fontFamily: fonts.cormorandMedium,
        paddingBottom: "25px",
        maxWidth: "960px"
    },
    LinkedinImage: {
        paddingBottom: "35px",
        cursor: "pointer",
    },
}