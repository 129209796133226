import React from "react";
import Flex from "../ui/Flex";


export default function Loading() {
    return (
        <Flex {...styles.Container}>
            LOADING
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "pink"
    }
}