import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import Text from "../ui/Text";
import { colors, fonts } from "../ui/theme/theme";
import Image from "../ui/Image";
import dinnerTable from "../../assets/images/dinnerTable.webp"

export default function Giving() {
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Text {...styles.TitleText}>Giving</Text>
            <Text {...styles.SubText}>We aim to restore the climate, one meal at a time</Text>
            <Flex {...styles.Body}>
                <Flex {...styles.ContentContainer}>
                    <Image src={dinnerTable} {...styles.DinnerTableImage}/>
                    <Flex {...styles.RightContainer}>
                        <Text {...styles.GivingTitleText}>Let’s eat our way out of the climate crisis!</Text>
                        <Text {...styles.GivingParagraphText}>
                            Founded by chefs, Le Savoir Fare recognizes that cooks experience a unique relationship with the world. On one side, we tend to communities and families, and perpetually seek a connection with the people that we nourish. On the other side, we are intimately connected with nature and everything it has to offer. Therefore, we find ourselves continuously seeking ways to harmonize our care for people, animals, and long to promote practices that are beneficial for the environment.
                        </Text>
                        <Text {...styles.GivingParagraphText}>
                            Le Savoir Fare was created in the wake of the COVID pandemic and the unprecedented Lightning Complex Fires that were caused by global warming. These fires alone were responsible for scorching nearly  400,000 acres in California, thus resulting in dark red skies and the worst air quality in the world.
                        </Text>
                        <Text {...styles.GivingParagraphText}>
                            We invite you to take a seat at our table to make a stand against irresponsible farming practices and wasteful production methods that harm our planet.
                        </Text>
                        <Text {...styles.GivingParagraphText}>
                            Join us on the front lines by supporting the Zero FoodPrint organization, the Regenerative Organic Alliance, and Le Savoir Fare
                        </Text>
                        <Flex {...styles.ButtonRow}>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("https://www.zerofoodprint.org/")
                            }}>
                                <Text {...styles.ButtonText}>Zero FoodPrint</Text>
                            </Flex>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("https://regenorganic.org/")
                            }}>
                                <Text {...styles.ButtonText}>Regenerative Organic Alliance</Text>
                            </Flex>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("/contact-us")
                            }}>
                                <Text {...styles.ButtonText}>Le Savoir Fare</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </Flex>
    );
}
const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "30px"
    },
    SubText: {
        fontFamily: fonts.cormorand,
        fontSize: "18px",
        lineHeight: "24px",
        color: colors.olive,
        maxWidth: "750px",
        textAlign: "center",
        paddingBottom: "40px"
    },
    Body: {
        paddingLeft: ["0px", "40px"],
        paddingRight: ["0px", "40px"],
        width: "100vw",
    },
    ContentContainer: {
        backgroundColor: colors.beigeBackground,
        width: "100%",
        flexDirection: ["column-reverse", "row"],
        justifyContent: "flex-start"
    },
    DinnerTableImage: {
        width: ["100%", "50%"]
    },
    RightContainer: {
        width: ["100%", "50%"],
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "45px",
    },
    GivingTitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "45px",
        maxWidth: ["85vw", "460px"],
    },
    GivingParagraphText: {
        fontFamily: fonts.cormorand,
        fontSize: "18px",
        color: colors.olive,
        paddingBottom: "28px",
        maxWidth: ["85vw", "460px"],
        textAlign: "justify"
    },
    ButtonRow: {
        flexDirection: ["column", "row"],
        width: [null, "555px"],
        height: ["230px", null],
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: ["10px", "60px"],
        paddingBottom: ["30px", null]
    },
    ButtonText: {
        color: colors.beigeBackground,
        fontFamily: fonts.cormorand,
        fontSize: "18px",
    },
    Button: {
        backgroundColor: colors.olive,
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingTop: "12px",
        paddingBottom: "12px",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        hoverOpacity: "0.9"
    }

}