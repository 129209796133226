import React from "react";
import styled from "styled-components";
import {
    border,
    color,
    layout,
    opacity,
    shadow,
    space,
    position,
} from "styled-system";

import {
    filter,
    transform,
} from "./theme/customProperties";

export default React.memo(function Image({ src, src2x, ...props }) {
    return <ImageElement src={src} srcSet={`${src2x || src} 2x`} {...props} />;
});

Image.defaultProps = {
    display: "block",
};

const ImageElement = React.memo(styled.img`
    ${border};
    ${color};
    ${layout};
    ${opacity};
    ${shadow};
    ${space};
    ${position};
    ${filter};
    ${transform};
`);
