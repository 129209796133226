import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import Text from "../ui/Text";
import Image from "../ui/Image";
import { colors, fonts } from "../ui/theme/theme";

import bees from "../../assets/images/bees.webp";
import field from "../../assets/images/field.webp";
import goat from "../../assets/images/goat.webp";


export default function Contributors() {
    return (
        <Flex {...styles.Container}>
            <Header/>
                <Text {...styles.TitleText}>Contributors</Text>
                <Text {...styles.SubText}>We responsibly source high-quality produce from local organic farmers who are actively taking part in the sustainable and regenerative agricultural movement, so we can craft climate-friendly dishes for you. </Text>
                <Flex {...styles.ImagesContainer}>
                    <Image src={goat} {...styles.ThirdImage}/>
                    <Image src={bees} {...styles.ThirdImage}/>
                    <Image src={field} {...styles.ThirdImage}/>
                </Flex>
                <Flex {...styles.LinksContainer}>
                    {
                        columns.map((column) => {
                            return(
                                <Flex {...styles.LinksColumn}>
                                    {
                                        column.map(({label, link}) => {
                                            return (
                                                <Flex 
                                                    {...styles.LinkText}
                                                    onClick={() => {
                                                        window.open(link)
                                                    }}
                                                >
                                                    -{" "}{label}
                                                </Flex>
                                            );
                                        })
                                    }
                                </Flex>
                            );
                        })
                    }
                </Flex>
            <Footer/>
        </Flex>
    );
}



const columnOne = [
    {label: "Acme Bread ", link: "https://acmebread.com/"},
    {label: "Alexander Family Farm", link: "https://alexandrefamilyfarm.com/pages/regenerative"},
    {label: "Bivalve Dairy", link: "https://bivalvedairy.com"},
    {label: "Capay Mills", link: "https://www.capaymills.com/"},
    {label: "Capay Organic", link: "http://www.capayorganic.com/"},
    {label: "City Bees", link: "http://www.citybees.com/about.htm"},
    {label: "Cowgirl Creamery", link: "https://cowgirlcreamery.com/pages/our-mission"},
    {label: "Devil’s Gulch Ranch", link: "https://www.devilsgulchranch.com/"},
    {label: "Dirty Girl Produce", link: "https://www.dirtygirlproduce.com/about"},
    {label: "Eat Well Farms ", link: "https://www.eatwell.com/about-the-farm"},
    {label: "Far West Fungi", link: "https://farwestfungi.com/pages/our-farm"},
    {label: "Fifth Crow Farm", link: "https://fifthcrowfarm.com/about/"},
    {label: "Frog Hollow Farms ", link: "https://www.froghollow.com/pages/about-us"},
];

const columnTwo = [
    {label: "Heirloom Organic Gardens", link: "https://heirloom-organic.com/"},
    {label: "Hog Island Oysters", link: "https://hogislandoysters.com/our-story/sustainability/"},
    {label: "Kashiwase Farms", link: "https://montereybayfarmers.org/"},
    {label: "Knoll Organics", link: "https://www.knollorganics.com/"},
    {label: "Little Apple Treats", link: "https://www.littleappletreats.com/pages/little-apple-treats-orchards"},
    {label: "Little Organic Farm", link: "http://www.thelittleorganicfarm.com/Home.html"},
    {label: "Lucero Organic Farms", link: "https://www.luceroorganicfarms.net/about/"},
    {label: "Markegard Family", link: "https://www.markegardfamily.com/"},
    {label: "Massa Organics", link: "https://www.massaorganics.com"},
    {label: "Mindful Meats", link: "https://www.mindfulmeats.com/"},
    {label: "Morris Grass Fed", link: "https://morrisgrassfed.com/climate-change-ranching/"},
    {label: "Oya Organics", link: "https://oyaorganicfarm.com/about-us/"},
];

const columnThree = [
    {label: "Point Reyes Cheese", link: "https://pointreyescheese.com/pages/the-farm#the_story"},
    {label: "Roots&Clay Farm", link: ""},
    {label: "San Francisco Honey and Pollen", link: ""},
    {label: "Skyhill Farms", link: "http://skyhillfarms.com/sustainability/"},
    {label: "Spade & Plow Organics ", link: "https://www.spadeandplow.com/the-farm"},
    {label: "Stemple Creek Ranch", link: "https://stemplecreek.com/pages/our-story"},
    {label: "Straus Dairy Farm ", link: "https://www.strausfamilycreamery.com/about/"},
    {label: "Tierra Vegetables", link: "https://www.tierravegetables.com/"},
    {label: "Tomales Farmspread Creamy", link: "https://www.tolumafarms.org/"},
    {label: "Tomatero Organic Farm ", link: "https://www.tomaterofarm.com/"},
    {label: "True Grass Farms", link: "https://www.truegrassfarms.com/"},
    {label: "Water2Table", link: "https://www.water2table.com/"},
    {label: "Yerena Farms", link: ""}
];

const columns = [
    columnOne,
    columnTwo,
    columnThree,
]

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "30px"
    },
    SubText: {
        fontFamily: fonts.cormorand,
        fontSize: "18px",
        lineHeight: "24px",
        color: colors.olive,
        maxWidth: ["80vw", "750px"],
        textAlign: ["justify", "center"],
    },
    ImagesContainer: {
        width: "100vw",
        flexDirection: "row",
        paddingTop: "30px"
    },
    ThirdImage: {
        width: "33.33%"
    },
    LinksContainer: {
        flexDirection: ["column", "row"],
        alignItems: "flex-start",
        justifyContent: "center",
        maxWidth: "1050px",
        paddingTop: ["40px", "70px"],
        paddingBottom: ["20px", "40px"]
    },
    LinksColumn: {
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "330px"
    },
    LinkText: {
        cursor: "pointer",
        fontSize: "20px",
        fontFamily: fonts.cormorand,
        color: colors.olive,
    },
}